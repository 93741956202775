<template>
    <v-container
        fluid
        class="fill-height"
    >
        <v-row justify="center">
            <v-col
                cols="12"
                md="4"
            >
                <v-form
                    ref="createUserForm"
                    v-model="valid"
                >
                    <v-card
                        tile
                        elevation="5"
                    >
                        <v-card-title class="py-1">
                            {{ $t('systemsetting-5') }}
                        </v-card-title>
                        <v-card-subtitle class="py-3">
                            {{ $t('createUser') }}
                        </v-card-subtitle>
  
                        <v-card-text class="ma-0 py-0">
                            <v-text-field
                                v-model="email"
                                :disabled="working"
                                tabindex="1"
                                :rules="globalEmailValidator(email)"
                                :label="$t('myuser/profile-email')"
                                type="email"
                                inputmode="email"
                                autocapitalize="off" 
                                autocorrect="off" 
                                autocomplete="email"
                                required
                            />
                            <v-text-field
                                v-model="phoneNumber"
                                :disabled="working"
                                type="tel" 
                                inputmode="tel" 
                                autocorrect="off" 
                                autocomplete="tel"
                                tabindex="2"
                                :rules="globalPhoneNumberValidator(phoneNumber)"
                                :label="$t('myuser/profile-phonenumber')"
                                required
                            />
                            <v-text-field
                                v-model="password"
                                :disabled="working"
                                tabindex="3"
                                :type="showPassword ? 'text' : 'password'"
                                :rules="globalPasswordValidator(password)"
                                :label="$t('forcepasswordchange-tab1title')"
                                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                required
                                @click:append="showPassword = !showPassword"
                            />
                            <v-text-field
                                v-model="confirmPassword"
                                :disabled="working"
                                tabindex="4"
                                :type="showPassword ? 'text' : 'password'"
                                :rules="globalConfirmPasswordValidator(confirmPassword, password)"
                                :label="$t('confirmPassword')"
                                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                required
                                @click:append="showPassword = !showPassword"
                            />                            
                            <v-text-field
                                v-model="cvrNumber"
                                :readonly="working"
                                type="text" 
                                inputmode="decimal" 
                                pattern="[0-9]*" 
                                autocorrect="off"
                                tabindex="5"
                                :rules="globalCvrNumberValidator(cvrNumber)"
                                :error-messages="cvrErrors"
                                :label="$t('registrations-vat')"
                                required
                            />
                            <v-text-field
                                v-if="companyName"
                                v-model="companyName"
                                :label="$t('companyName')"
                                readonly
                                disabled
                            />              

                            <template v-for="code in responseCodes">
                                <v-alert
                                    :key="code"
                                    class="ma-0 mb-1"
                                    dense
                                    :type="requestStatus ? 'success' : 'error'"
                                >
                                    <template v-if="requestStatus">
                                        {{ $t('success-' + code) }}
                                    </template>
                                    <template v-else>
                                        {{ $t('error-' + code) }}
                                    </template>
                                </v-alert>
                            </template>
                        </v-card-text>

                        <v-card-actions>
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-btn
                                            :to="{ name: 'Login'}"
                                        >
                                            {{ $t('buttonAbort') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col                                        
                                        class="text-right"
                                    >
                                        <v-btn
                                            :disabled="working || !valid || companyName === null"
                                            type="submit"
                                            color="#75ad40"
                                            @click="ClickCreateUser"
                                        >
                                            {{ $t('common-Continue') }}
                                        </v-btn>                                        

                                        <v-dialog
                                            v-model="dialog"
                                            max-width="300px"
                                        >
                                            <v-card>
                                                <v-card-title class="py-1">
                                                    {{ $t('basket-buttonConfirm') }}
                                                </v-card-title>
                                                <v-card-text>
                                                    {{ $t('confirmCreateUserFor') }}
                                                    <br>
                                                    <b>{{ companyName }} ({{ $t('leftMenu/admin-VAT') }}: {{ cvrNumber }})</b>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-btn
                                                        @click="dialog = false"
                                                    >
                                                        {{ $t('buttonAbort') }}
                                                    </v-btn>
                                                    <v-spacer />
                                                    <v-btn
                                                        :disabled="working || !valid"
                                                        type="submit"
                                                        color="#75ad40"
                                                        @click="ClickConfirmCreateUser"
                                                    >
                                                        {{ $t('settings/userlist-users-createButton') }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex'


export default {
    data() {
        return {
            working: false,

            valid: false,

            email: null,
            phoneNumber: null,
            password: null,
            confirmPassword: null,
            showPassword: false,

            cvrNumber: null,
            companyName: null,

            //Errors
            responseSuccess: false,
            requestStatus: false,
            responseCodes: [],

            //Cvr-mismatch
            cvrErrors: [],

            //Confirm dialog
            dialog: false
        }
    }, 
    computed: {
    },
    watch: {
        cvrNumber: function(val){
            this.cvrErrors = []
            if(this.globalCvrNumberValidator(val).length === 0){
                this.CheckCvrNumber()
            }  
            else{
                this.companyName = null
            }          

        }
    },
    created() {        
    },    
    methods: {
        ClickCreateUser(e){
            e.preventDefault()
            this.dialog = true
        },
        ClickConfirmCreateUser(e){
            this.working = true
            this.responseSuccess = false
            this.responseCodes = []

            //Important when using 'history' mode in router
            e.preventDefault()
            this.CreateUser({
                email: this.email,
                cvrNumber: this.cvrNumber,
                phoneNumber: this.phoneNumber,
                password: this.password
            })
                .then(response => {
                    this.responseSuccess = response.success
                    if(response.success){
                        this.requestStatus = true
                        this.$router.push({ name: 'CreateUserAccepted' })
                    }
                    else {
                        this.requestStatus = false
                        //in this case both error code 
                        if(response.codes.some(e => e === 'userusernamealreadyinuse') && response.codes.some(e => e === 'useremailalreadyinuse')){
                            response.codes = response.codes.filter(item => {
                                return item !== 'userusernamealreadyinuse'
                            })
                        }
                        this.responseCodes = response.codes
                    }       
                                        
                    this.working = false
                    this.dialog = false
                })
        },
        CheckCvrNumber() {
            this.working = true
            this.responseCodes = []
            
            this.ValidateCvrNumber({cvrNumber: this.cvrNumber})
                .then(response => {
                    if(response.name !== null){
                        this.companyName = response.name
                    }
                    else {
                        this.cvrErrors.push(this.$t('companyVatNotFound'))
                    }       
                    
                    this.working = false
                })
        },        
        ...mapActions({
            CreateUser: 'CreateUser',
            ValidateCvrNumber: 'ValidateCvrNumber'
        })
    }
}
</script>
